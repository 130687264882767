import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
import loginBG from '../assets/images/loginBG.png';
import loginLogo from '../assets/images/loginLogo.svg';
// components
// import Logo from '../components/logo';

// sections
import { LoginForm } from '../sections/auth/login';
import config from '../config/config';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    marginLeft: 'unset',
  },
}));

const StyledSection = styled('div')(() => ({
  width: '100%',
  // maxWidth: 480,
  maxWidth: 600,
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: 'none',
  backgroundColor: 'transparent',
}));

const StyledContent = styled('div')(({ theme }) => ({
  Width: '100%',
  maxWidth: 380,
  margin: 'auto',
  flex: 1,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const { appName } = config;


  useEffect(() => {
    const isLoggedIn = window.localStorage.getItem('ds-token');
    if (isLoggedIn) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title> Login | {appName} </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        

        <Container maxWidth="sm">
          <StyledContent>
            <span><img className='login-img-slogan' src={loginLogo} alt="logo" /></span>
            <br/>
            <Typography variant="h4" textAlign={'center'} gutterBottom>Login</Typography>
            <LoginForm />
          </StyledContent>
        </Container>

        {mdUp && (
          <StyledSection className='login-bg-sec'>
            <img className='login-img' src={loginBG} alt="login" />
          </StyledSection>
        )}
        
      </StyledRoot>
    </>
  );
}
