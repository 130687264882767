import React, { useState, useEffect }  from 'react';
import { useLocation } from 'react-router-dom';
import DrawerAppBar from '../../../components/BurgerMenu/DrawerAppBar';


const NavHeader = () => {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();
    const [currentURL, setCurrentURL] = useState('')
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20)
      
    })
    setCurrentURL(location.pathname); // to set the current url
  }, [location.pathname])

 


    return (
        <div className={`${scroll ? "main-header scrolled" : "main-header"} project-main-header ${currentURL === '/' ? 'inside-home-wrp': 'outside-home-wrp'} f-wrp`}>
            <div className="container">
                <DrawerAppBar />
            </div>
        </div>
    )
}

export default NavHeader;