import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Stack, Button, Grid, InputAdornment, TextField } from '@mui/material';
import NavHeader from '../../layouts/dashboard/header/NavHeader';

import BackArrow from '../../assets/images/BackArrow.svg';
import edit from '../../assets/images/edit.svg';
import ArrowsOut from '../../assets/images/ArrowsOut.svg';
import focus from '../../assets/images/focus.svg';

import map from '../../assets/images/map.png';
import { getProperty, updateProperty } from '../../services/api/property';
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor/index';
// ----------------------------------------------------------------------
import AwsS3ImageUploader from '../../components/AwsS3ImageUploader';

export default function EditListItem() {
  const params = useParams();
  const navigator = useNavigate();
  const propertyId = params?.id || '';
  const [propertyItem, setPropertyItem] = useState(null);

  useEffect(() => {
    if (propertyId) {
      getPropertyAs();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Get the property by its id;
   */
  const getPropertyAs = () => {
    getProperty(propertyId).then((property) => setPropertyItem({ ...property }))

  }

  /**
  Sets the value of the property item
  */
  const setValue = (name, value) => {
    const newProp = { ...propertyItem };
    newProp[name] = value;
    setPropertyItem({ ...newProp });
  }

  /**
   * Handles the form submit
   */
  const handleSubmit = () => {
    if (propertyItem.images.length > 0 && propertyItem.title && propertyItem.subTitle && propertyItem.price && propertyItem.plot && propertyItem.description && propertyItem.agentName && propertyItem.location) {
      const postData = {
        images: propertyItem.images.map((img) => img.fileKey),
        thumbnails: propertyItem?.thumbnails.filter((i)=>i && i.tempURL).map((img) => img.fileKey) || [],
        title: propertyItem.title,
        subTitle: propertyItem.subTitle,
        price: propertyItem.price,
        plot: propertyItem.plot,
        description: propertyItem.description,
        location: propertyItem.location,
        features: propertyItem.features,
        bua: propertyItem.bua,
        agentName: propertyItem.agentName,
        referent: propertyItem.agentName
      }
      updateProperty(propertyId, postData).then(() => {
        showSnackbar({}, () => navigator('/dashboard/app')).success('Property updated successfully')
      })

    } else {
      showSnackbar().warning('Please fill all required fields');
    }
  }

  return (
    <>
      <NavHeader />
      <Container>

        <div className='list-main-wrp f-wrp'>
          <div className="breadcrumb f-wrp">
            <Link to={"/dashboard/app"}><img src={BackArrow} alt="back-arrow" />back</Link>
          </div>
          <div className='title-head f-wrp'>
            <div className='title-sec'>
              <Typography variant="h4" gutterBottom>Edit listing</Typography>
            </div>
            <div className='button-sec'>
              <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
              <Button onClick={handleSubmit} className='fill-btn'>Save changes</Button>
            </div>
          </div>

          {(propertyItem) && <><h3>Images</h3><AwsS3ImageUploader
            key={'uploader-img'}
            uploaderKey={'uploader-img'}
            defaultImages={propertyItem?.images || []}
            onUploadEnd={(images) => setValue("images", images)}
            onRemoveFile={(images) => setValue("images", images)}
          /></>}
          
         
          {(propertyItem) && <div className='info-sec-wrp f-wrp'>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>General info:</span>
                <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      defaultValue={propertyItem.title}
                      name="title"
                      label="Title:"
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Subtitle"
                      label="Subtitle:"
                      name='subTitle'
                      defaultValue={propertyItem.subTitle}
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="price"
                      label="price"
                      name='price'
                      defaultValue={propertyItem.price}
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            AED
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Plotsft"
                      label="Plot sft:"
                      name='plot'
                      defaultValue={propertyItem.plot}
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={ArrowsOut} alt="back-arrow" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="BUAsft"
                      label="BUA sft:"
                      name='bua'
                      defaultValue={propertyItem.bua}
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={focus} alt="back-arrow" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      type='text'
                      name="agentName"
                      title="Agent name"
                      defaultValue={propertyItem.agentName}
                      label="Agent name:"
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                    />
                  </Stack>
                </Grid>
                <Grid style={{ display: 'none' }} item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    {/* <TextField
                      type='text'
                      name="Other"
                      label="Other:"
                      defaultValue="&nbsp;" /> */}
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Description:</span>
                <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Description"
                      label="Description:"
                      name='description'
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      multiline
                      rows={4}
                      defaultValue={propertyItem.description}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Features:</span>
                <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <RichTextEditor defaultValue={propertyItem.features} onChange={(value) => setValue('features', JSON.stringify(value))} />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Location</span>
                <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      name="location"
                      label="Area:"
                      onChange={({ target: { name, value } }) => setValue(name, value)}
                      defaultValue={propertyItem.location}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <img src={map} alt='map' />
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </div>}
          <div className='button-sec f-wrp'>
            <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
            <Button onClick={handleSubmit} className='fill-btn'>Save changes</Button>
          </div>
        </div>
      </Container>
    </>
  );
}
