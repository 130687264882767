import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(7)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/properties/propImg_${index + 1}.jpg`,

  name: sample([
    'Modern Villa',
    'Modern Villa',
    'Modern Villa',
    'Modern Villa',
  ]),
  price: sample([
    'AED 160.000.000',
    'AED 160.000.000',
    'AED 160.000.000',
    'AED 160.000.000',
  ]),
  location: sample([
    'Jumeirah',
    'Jumeirah',
    'Jumeirah',
    'Jumeirah',
  ]),
  plot: sample([
    '16500 sft',
    '16500 sft',
    '16500 sft',
    '16500 sft',
  ]),
  agent: sample([
    'Tamara Alé',
    'Tamara Alé',
    'Tamara Alé',
    'Tamara Alé',
  ]),
  status: sample([
    'complete',
    'incomplete',
  ]),








  date: sample([
    '01/10/23',
    '01/07/23',
    '01/03/23',
    '12/30/22',
  ]),
  // name: sample([
  //   'Jhon',
  //   'Mark',
  //   'Sandra',
  //   'Michael',
  // ]),
  lastname: sample([
    'Guiness',
    'Lewis',
    'Gonzalez',
    'Brown',
  ]),
  phone: sample([
    '+1 34 555 678',
    '+1 34 555 678',
    '+1 34 555 678',
    '+1 34 555 678',
  ]),
  email: sample([
    'johnguiness@gmail.com',
    'm.lewis@gmail.com',
    'Sandragonzalez@gmail.com',
    'brownmike@gmail.com',
  ]),
  quote: sample([
    '3500 usd',
    '3500 usd',
    '3500 usd',
    '3500 usd',
  ]),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  // status: sample([
  //   // 'complete',
  //   // 'incomplete',
  //   'Requested',
  //   'Visited',
  //   'High Probability',
  //   'Out',
  // ]),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
}));

export default users;
