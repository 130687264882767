
import { Grid, Container } from '@mui/material';
import ProfileForm from '../components/profileForm';


// ----------------------------------------------------------------------

export default function Profile() {


  return (
    <>
      <Container className='profile-main-wrpp f-wrp' maxWidth="md">
        <Grid container spacing={3}>
          <ProfileForm />
        </Grid>
      </Container>
    </>
  );
}
