import { useState } from 'react';
import { Typography, Container, Grid, Stack, IconButton, InputAdornment, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '../iconify';

export default function ProfileForm() {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Container className='profile-main-wrpp f-wrp' maxWidth="xl">
        <Typography variant="h4" gutterBottom>General details</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Stack className='login-form profile-form' spacing={3}>
              <TextField type='text'
                //  onChange={(e) => setEmail(e.target.value)}
                name="First name" label="First name" />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Stack className='login-form profile-form' spacing={3}>
              <TextField type='text'
                //  onChange={(e) => setEmail(e.target.value)}
                name="Last name" label="Last name" />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Stack className='login-form profile-form' spacing={3}>
              <TextField type='number'
                //  onChange={(e) => setEmail(e.target.value)}
                name="Phone" label="Phone" />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Divider />
        </Stack>
        <Typography variant="h4" gutterBottom>Login info</Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Divider />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Stack className='login-form profile-form' spacing={3}>
              <TextField type='email'
                //  onChange={(e) => setEmail(e.target.value)}
                name="email" label="Email" />

            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Stack className='login-form profile-form' spacing={3}>
              <TextField
                name="password"
                label="Password"
                // onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end">
                        <Iconify
                          icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <LoadingButton
            fullWidth
            // disabled={!email || !password}
            size="large"
            type="submit"
            variant="contained"
            style={{display: 'block', marginTop: '50px'}}
            >
            {/* onClick={onSubmit}> */}
            Save
          </LoadingButton>
        </Grid>


      </Container>




    </>
  );
}
