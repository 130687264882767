import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import AppIcons from '../../assets/images/icons';
// import NavClose from '../../assets/images/icons/navClose.svg';

import Logo from '../../assets/images/Logo.png';

// import Home from '../../assets/images/icons/home.svg';
// import Listing from '../../assets/images/icons/listing.svg';
// import Aboutus from '../../assets/images/icons/about.svg';
// import ContactUs from '../../assets/images/icons/contact.svg';
// import Login from '../../assets/images/icons/login.svg';


import './DrawerApp.css'


const drawerWidth = 240;
// const navItems = [
//   {
//     title: 'Login',
//     // icon: Home,
//     link: '/login'
//   },
// ];
const isLoggedIn = window.localStorage.getItem('ds-token');
const navItems = isLoggedIn ? [
  {
    title: 'All Listings',
    // icon: Home,
    link: '/dashboard/app'
  },
  {
    title: 'ContactUs-Queries',
    // icon: Home,
    link: '/contact-us-queries'
  },
  {
    title: 'Logout',
    // icon: Home,
    link: '/logout'
  },
] : [
  {
    title: 'Login',
    // icon: Home,
    link: '/login'
  },
];

const mobNavItems = isLoggedIn ? [
  {
    title: 'Logout',
    // icon: Home,
    link: '/login'
  },
] : [
  {
    title: 'Login',
    // icon: Home,
    link: '/login'
  },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className='mob-drawer'>

      <div className="close-btn f-wrp">
        {/* <span><img src={NavClose} alt="Nav Close Icon" /></span> */}
      </div>

      {/* <Divider /> */}
      <List>
        {mobNavItems.map((element) => (
          <ListItem key={element.title} disablePadding>
            <Link to={`${element.link}`} style={{ width: '100%' }} >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <span className='menu-icon'><img src={element.icon} alt="Menu-icon" /></span>
                <ListItemText primary={element.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" className="custom-navbar" >
        <Toolbar className="custom-header" style={{ justifyContent: 'space-between' }}>
          <IconButton
            className='pc-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}>
                {/* <img src={AppIcons.logo} alt="Propshop Logo" /> */}dd
                </Link></span>
            </div>
          </IconButton>
          <IconButton
            className='mob-icon'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className="logo-wrp f-wrp">
              <span>
                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33337 9.33337H9.33337M26.6667 9.33337H14.6667M26.6667 22.6667H22.6667M5.33337 22.6667H17.3334M5.33337 16H26.6667" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" />
                </svg> */}
              </span>
            </div>
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <div className="logo-wrp f-wrp">
              <span><Link to="/" style={{ display: 'inline-block' }}>
                <img src={Logo} alt="Propshop Logo" />
                </Link></span>
            </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((element, key) => (
              <Link to={`${element.link}`} key={`link-${key}`} style={{ width: '100%' }} >
                <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                  {element.title}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          className='mob-draw-wrp'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;