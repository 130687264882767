
import { Grid, Container } from '@mui/material';
import NavHeader from '../layouts/dashboard/header/NavHeader';


// ----------------------------------------------------------------------

export default function WelcomePage() {


  return (
    <>
      <div className='welcome-container f-wrp'>
        <NavHeader />
        <Container>
          <Grid className='welcome-bnner'>
            <div className='contnt-wrp f-wrp'>
              <h4>Welcome to</h4>
              <h1>Propshop Real Estate <br /><span>Admin portal</span></h1>
            </div>
          </Grid>
        </Container>
      </div>

    </>
  );
}
