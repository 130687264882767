import instance from './axios';

const axiosInstance = instance;

// login via email and password
/**
 * Admin login
 * @param {Object} data email and password
 */
export const getEnquires = async () => {
    try {
        const result = await axiosInstance.get('/user-query');
        const data = result.data?.results || [];        
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}