import * as React from 'react';

import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';



import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';



// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';





// components
// import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';


import tick from '../assets/images/tick.svg';
import mail from '../assets/images/mail.svg';
import phone from '../assets/images/phone.svg';
import pointer from '../assets/images/pointer.svg';
import calendar from '../assets/images/calendar.svg';
import pitch from '../assets/images/pitch.svg';
import money from '../assets/images/money.svg';
import globe from '../assets/images/globe.svg';
import solar from '../assets/images/solar.svg';
import bill from '../assets/images/bill.svg';
import NavHeader from '../layouts/dashboard/header/NavHeader';




// ----------------------------------------------------------------------



const TABLE_HEAD = [
  { id: 'date', label: 'Request date', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'lastname', label: 'Last name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone number', alignRight: false },
  { id: 'quote', label: 'Quote', alignRight: false },
  { id: 'Progress', label: 'Progress', alignRight: false },
  { id: '' },
  // { id: 'company', label: 'Company', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RequestPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  const [openmodal, setOpenmodal] = React.useState(false);
  const handleOpen = () => {
    setOpenmodal(true);
  };
  const handleClose = () => {
    setOpenmodal(false);
  };


  const [statusvalue, setStatusvalue] = React.useState();

  const handleChange = (event) => {
    setStatusvalue(event.target.value);
  };


  
















  return (
    <>
      <Helmet>
        <title> Admin</title>
      </Helmet>
      <NavHeader />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Requests
          </Typography>

          {/* <Link to={"/dashboard/new-request"}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New
            </Button>
          </Link> */}
        </Stack>

        <Card className='table-request'>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, date, name, lastname, email, phone, quote, status, avatarUrl } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" className='filter-head' selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell align="center" component="th" scope="row" padding="none">
                          <Stack direction="row" align="center" justifyContent="center" alignItems="center" spacing={2}>
                            <Avatar style={{ display: 'none' }} alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" align="center" style={{ fontWeight: 'normal' }} noWrap>
                              {date}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{name}</TableCell>

                        <TableCell align="center">{lastname}</TableCell>

                        <TableCell align="center">{email}</TableCell>

                        <TableCell align="center">{phone}</TableCell>

                        <TableCell align="center">{quote}</TableCell>

                        <TableCell align="center">
                          {/* <Label color={(status === 'Out' && 'error') || (status === 'High Probability' && 'warning') || (status === 'Visited' && 'info') || 'success'}>{sentenceCase(status)}</Label> */}
                          {/* {(status === 'Out' && <span className='Out tabstatus'>{sentenceCase(status)}</span>) ||
                            (status === 'High Probability' && <span className='HighProbability tabstatus'>{sentenceCase(status)}</span>) ||
                            (status === 'Visited' && <span className='Visited tabstatus'>{sentenceCase(status)}</span>) ||
                            (status === 'Requested' && <span className='Requested tabstatus'>{sentenceCase(status)}</span>)} */}

                          <Box className="status-select" sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Status</InputLabel>
                              {(status === 'Out' && <>

                                <Select
                                  labelId="status select"
                                  id="status-select"
                                  value={statusvalue}
                                  label="status"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={'Out'}><span className='Out tabstatus'>{sentenceCase(status)}</span></MenuItem>
                                  <MenuItem value={'HighProbability'}><span className='HighProbability tabstatus'>HighProbability</span></MenuItem>
                                  <MenuItem value={'Visited'}><span className='Visited tabstatus'>Visited</span></MenuItem>
                                  <MenuItem value={'Requested'}><span className='Requested tabstatus'>Requested</span></MenuItem>
                                </Select></>) ||
                                (status === 'High Probability' && <>
                                
                                <Select
                                  labelId="status select"
                                  id="status-select"
                                  value={statusvalue}
                                  label="status"
                                  onChange={handleChange}
                                >
                                  
                                  <MenuItem value={'Out'}><span className='Out tabstatus'>Out</span></MenuItem>
                                  <MenuItem value={'HighProbability'}><span className='HighProbability tabstatus'>{sentenceCase(status)}</span></MenuItem>
                                  <MenuItem value={'Visited'}><span className='Visited tabstatus'>Visited</span></MenuItem>
                                  <MenuItem value={'Requested'}><span className='Requested tabstatus'>Requested</span></MenuItem>
                                </Select></>) ||
                                (status === 'Visited' && <>
                                
                                <Select
                                  labelId="status select"
                                  id="status-select"
                                  value={statusvalue}
                                  label="status"
                                  onChange={handleChange}
                                >
                                  
                                  <MenuItem value={'Out'}><span className='Out tabstatus'>Out</span></MenuItem>
                                  <MenuItem value={'HighProbability'}><span className='HighProbability tabstatus'>High Probability</span></MenuItem>
                                  <MenuItem value={'Visited'}><span className='Visited tabstatus'>{sentenceCase(status)}</span></MenuItem>
                                  <MenuItem value={'Requested'}><span className='Requested tabstatus'>Requested</span></MenuItem>
                                </Select></>) ||
                                (status === 'Requested' && <>
                                
                                <Select
                                  labelId="status select"
                                  id="status-select"
                                  value={statusvalue}
                                  label="status"
                                  onChange={handleChange}
                                >
                                  
                                  <MenuItem value={'Out'}><span className='Out tabstatus'>Out</span></MenuItem>
                                  <MenuItem value={'HighProbability'}><span className='HighProbability tabstatus'>High Probability</span></MenuItem>
                                  <MenuItem value={'Visited'}><span className='Visited tabstatus'>Visited</span></MenuItem>
                                  <MenuItem value={'Requested'}><span className='Requested tabstatus'>{sentenceCase(status)}</span></MenuItem>
                                </Select></>)}










                              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

                            </FormControl>
                          </Box>



                          {/* <RequestStatus /> */}

                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (   
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleOpen}>
          {/* <Link to={'/dashboard/new-request'} style={{display: 'flex', color: 'rgba(230, 143, 19, 1)', margin: '0 auto'}}> */}
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          View
          {/* </Link> */}
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:close-fill'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>

      {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}

      <Modal
        open={openmodal}
        onClose={handleClose}
        aria-labelledby="new request"
        aria-describedby="new request"
        className='new-request-modal'
      >
        <Box className="new-request-wrpp">
          <div className='address-wrp f-wrp'>
            <ul>
              <li className='title'>Jhon Guiness <span> <img className='icon' src={tick} alt="icon" />  &nbsp;Owner</span></li>

              <li className='mail'><span><img className='icon' src={mail} alt="icon" />
              </span>johnguiness@gmail.com</li>
              <li className='phone'><span> <img className='icon' src={phone} alt="icon" />
              </span>+1 34 555 678</li>
              <li className='address'><span><img className='icon' src={pointer} alt="icon" />
              </span><b>Address:</b><p>4300 Spyres Way, Modesto, CA 95356, EE. UU</p></li>
            </ul>
          </div>
          <div className='note-sec f-wrp'>
            <TextField type='text-area' style={{ width: '100%' }}
              multiline
              rows={4}
              maxRows={6}
              name="Note" label="Notes" />
          </div>
          <div className='about-project-wrp f-wrp'>
            <span className='abt-title'>About the Project:</span>

            <div className='initial-info'>
              <span><img src={calendar} className='icon' alt="icon" />Project date: 12/12/2022 at 6.00 am</span>
            </div>

            <div className='info-table f-wrp'>
              <table>
                <tbody>
                  <tr>
                    <td><span>Neighbors with solar:</span><b>18</b></td>
                    <td><span>Electricity company:</span><b>Southern CA Edison</b></td>
                  </tr>
                  <tr>
                    <td><span>Available square feets:</span><b>1,075</b></td>
                    <td><span>Electric Vehicle:</span><b>NO</b></td>
                  </tr>
                  <tr>
                    <td><span>Sun light per day:</span><b>6.8 h</b></td>
                    <td><span>% electricity set off:</span><b>100%</b></td>
                  </tr>
                  <tr>
                    <td><span>Electricity bill:</span><b>U$D 300</b></td>
                    <td><span>% shade:</span><b>3%</b></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='roof-detail-table f-wrp'>
              <span className='roof-detail-title'> Roof: </span>
              <table>
                <tbody>
                  <tr>
                    <td><span>Orientation:</span><b>South</b></td>
                    <td><span>Pitch:</span><img src={pitch} className='icon' alt='icon' /></td>
                    <td><span>Aprox age:</span><b>20 years</b></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='snapshot-table f-wrp'>
              <span className='abt-title'>Snapshot:</span>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className='each-blk-snapshot f-wrp'>
                        <div className='img-sec'>
                          <img src={money} alt="icon" />
                        </div>
                        <div className='content-sec'>
                          <span> <b>$180</b> savings per month in your elecrticty bill.</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='each-blk-snapshot f-wrp'>
                        <div className='img-sec'>
                          <img src={globe} alt="icon" />
                        </div>
                        <div className='content-sec'>
                          <span> Your CO2 reduction is <b>19  tons per year</b>, the equivalent of <b>874 trees</b>.</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='each-blk-snapshot f-wrp'>
                        <div className='img-sec'>
                          <img src={solar} alt="icon" />
                        </div>
                        <div className='content-sec'>
                          <span> <b>25 solar panels</b> and <b>418 sqft</b> of available roof surface.</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='each-blk-snapshot f-wrp'>
                        <div className='img-sec'>
                          <img src={bill} alt="icon" />
                        </div>
                        <div className='content-sec'>
                          <span> Eligible for tax incentives.</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='summery-table f-wrp'>
              <span className='abt-title'>Summary <b className='status'>Completed</b></span>

              <table>
                <tbody>
                  <tr>
                    <td colSpan={1}><span># Panels:</span></td>
                    <td colSpan={1}><b>25</b></td>
                    <td colSpan={1}><b>U$D 24,000</b></td>
                  </tr>
                  <tr>
                    <td colSpan={1}><span>Instalation:</span></td>
                    <td colSpan={1}><b>YES</b></td>
                    <td colSpan={1}><b>U$D 6,845</b></td>
                  </tr>
                  <tr>
                    <td colSpan={1}><span>Batteries</span></td>
                    <td colSpan={1}><b>4</b></td>
                    <td colSpan={1}><b>U$D 4,899</b></td>
                  </tr>
                  <tr>
                    <td colSpan={1}><span>Decision / Next Step</span></td>
                    <td colSpan={1}><b>Reserving discount</b></td>
                    <td colSpan={1}><b>-5%</b></td>
                  </tr>
                </tbody>
              </table>

              <table className='total-summery'>
                <tbody>
                  <tr>
                    <td colSpan={2}><span>Total quote:</span></td>

                    <td colSpan={2}><b>U$D 33,956</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Button className='close-btn' onClick={handleClose}><Iconify icon={'eva:close-fill'} sx={{ mr: 2 }} /></Button>
        </Box>
      </Modal>
    </>
  );
}
