import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { TurnedIn } from '@mui/icons-material';
import { Container, Typography, Stack, Button, Grid, InputAdornment, TextField, IconButton, Tooltip } from '@mui/material';
import NavHeader from '../../layouts/dashboard/header/NavHeader';

import BackArrow from '../../assets/images/BackArrow.svg';
import ArrowsOut from '../../assets/images/ArrowsOut.svg';
import focus from '../../assets/images/focus.svg';

import map from '../../assets/images/map.png';
import { getProperty, updateProperty } from '../../services/api/property';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';
import { showSnackbar } from '../../components/Notification/Snackbar';


// ----------------------------------------------------------------------

export default function ViewProperty() {
  const params = useParams();
  const navigator = useNavigate();
  const propertyId = params?.id || '';
  const [propertyItem, setPropertyItem] = useState(null);

  useEffect(() => {
    if (propertyId) {
      getPropertyAs();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Get the property by its id;
   */
  const getPropertyAs = () => {
    getProperty(propertyId).then((property) => setPropertyItem(property))

  }

  const setThumbnail = (img)=> {
    const postData = {
      thumbnails: [img.fileKey]      
    }
    updateProperty(propertyId, postData).then(() => {
      getPropertyAs();
      
    }).finally(()=>showSnackbar().success('Thumbnail updated successfully'))
  }


  return (
    <>
      <NavHeader />
      <Container>

        <div className='list-main-wrp f-wrp'>
          <div className="breadcrumb f-wrp">
            <Link to={"/dashboard/app"}><img src={BackArrow} alt="back-arrow" />back</Link>
          </div>
          <div className='title-head f-wrp'>
            <div className='title-sec'>
              <Typography variant="h4" gutterBottom>View listing</Typography>
            </div>
            <div className='button-sec'>
              <Button onClick={() => navigator(`/dashboard/edit/${propertyItem.id}`)} className='fill-btn'>Edit</Button>
            </div>
          </div>

          <div className='img-upload-wrp f-wrp'>
            <h3>Images</h3>
            {propertyItem && propertyItem.images.map((img, k) => <div key={k} className='each-img-blk'>
              <span style={{ position: 'relative' }}>
                <Tooltip
                title={'Thumbnail'}
                >
                <IconButton
                  className='delete-btn'
                  aria-label="make as thumbnail"
                  onClick={() => setThumbnail(img)}
                  children={(propertyItem?.thumbnails && propertyItem.thumbnails.filter((i)=>i && i.tempURL).filter((thumb)=>thumb.fileKey === img.fileKey).length > 0 ) ? <TurnedIn /> : <TurnedInNotIcon/>} />
                  </Tooltip>
                <img style={{ width: '155px', height: '100px' }} src={img.tempURL} alt={img.fileKey} />
              </span>

            </div>)}


          </div>
          {(propertyItem) && <div className='info-sec-wrp f-wrp'>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>General info:</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      defaultValue={propertyItem.title}
                      name="title"
                      label="Title:"
                      disabled
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Subtitle"
                      label="Subtitle:"
                      name='subTitle'
                      defaultValue={propertyItem.subTitle}
                      disabled
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="price"
                      label="price"
                      name='price'
                      defaultValue={propertyItem.price}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            AED
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Plotsft"
                      label="Plot sft:"
                      name='plot'
                      defaultValue={propertyItem.plot}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={ArrowsOut} alt="back-arrow" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="BUAsft"
                      label="BUA sft:"
                      name='bua'
                      defaultValue={propertyItem.bua}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={focus} alt="back-arrow" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      type='text'
                      name="agentName"
                      title="Agent name"
                      defaultValue={propertyItem.agentName}
                      label="Agent name:"
                      disabled
                    />
                  </Stack>
                </Grid>
                <Grid style={{ display: 'none' }} item xs={12} sm={6} md={6}>
                  <Stack className='login-form profile-form' spacing={3}>
                    {/* <TextField
                      type='text'
                      name="Other"
                      label="Other:"
                      defaultValue="&nbsp;" /> */}
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Description:</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField
                      id="Description"
                      label="Description:"
                      name='description'
                      disabled
                      multiline
                      rows={4}
                      defaultValue={propertyItem.description}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Features:</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <RichTextViewer richText={propertyItem.features} />
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div className='general-info-sec f-wrp'>
              <div className='info-head f-wrp'>
                <span>Location</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <TextField type='text'
                      name="location"
                      label="Area:"
                      disabled
                      defaultValue={propertyItem.location}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack className='login-form profile-form' spacing={3}>
                    <img src={map} alt='map' />
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </div>}
          <div className='button-sec f-wrp'>
            <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
            <Button onClick={() => navigator(`/dashboard/edit/${propertyItem.id}`)} className='fill-btn'>Edit</Button>
          </div>
        </div>
      </Container>
    </>
  );
}
