import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import BlogPage from '../pages/BlogPage';
import RequestPage from '../pages/RequestPage';
import ListingPage from '../pages/property/ListingPage';
import LoginPage from '../pages/LoginPage';
import WelcomePage from '../pages/WelcomePage';
import Page404 from '../pages/Page404';
import ProductsPage from '../pages/ProductsPage';
// import DashboardAppPage from '../pages/DashboardAppPage';
import Profile from '../pages/Profile';
import EditListItem from '../pages/property/EditListItem';
import AddListItem from '../pages/property/AddListItem';
import ViewProperty from '../pages/property/ViewProperty';
import LogoutPage from '../pages/LogoutPage';
import ContactUsListingPage from '../pages/ContactUsQueries';

// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn = window.localStorage.getItem('ds-token');
  const routes = [
    
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: 'admin',
      element: <WelcomePage />,
    },
    {
      path: 'contact-us-queries',
      element: <ContactUsListingPage/>,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = isLoggedIn ? [
    {
      path: '/dashboard',
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        { path: 'app', element: <ListingPage /> },
        { path: 'request', element: <RequestPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'profile', element: <Profile /> },
        { path: 'edit/:id', element: <EditListItem /> },
        { path: 'property/view/:id', element: <ViewProperty /> },
        { path: 'add-property', element: <AddListItem /> },
      ],
    },
  ] : [];

  return useRoutes(routes.concat(privateRoutes));
}
