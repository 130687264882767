import config from '../../config/config';
// import instance from './axios';

// const axiosInstance = instance;

// login via email and password
/**
 * Admin login
 * @param {Object} data email and password
 */
export const adminLogin = async (data) => {

    const email = data?.email;
    const password = data?.password;
    const allowedEmail = config.adminEmail
    const allowedPassword = config.adminPassword
    if (email === allowedEmail && password === allowedPassword) {
        window.localStorage.setItem('ds-token', 'temp-token-till-implement')
        return {
            status: true,
            message: 'Logged in successfully'
        }
    }
    return {
        status: false,
        message: 'Invalid credentials'
    }


    // try {
    //     const result = await axiosInstance.post('/auth/admin-login', data);
    //     const authToken = result.data?.data?.token;
    //     if (authToken) {
    //         localStorage.setItem('token', authToken);
    //     }
    //     return ({ success: true , message: result.data?.message});
    // } catch (e) {
    //     const errorMessage = e.response?.data?.message;
    //     return ({ success: false , message: errorMessage});
    // }
}