import * as React from 'react';


import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';


// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';






// components
// import Label from '../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';


import NavHeader from '../../layouts/dashboard/header/NavHeader';
import innerBanner from '../../assets/images/Imgbanner.png';

import complete from '../../assets/images/complete.svg';
import incomplete from '../../assets/images/incomplete.svg';
import { deleteProperty, getProperties, updateProperty } from '../../services/api/property';
import { showSnackbar } from '../../components/Notification/Snackbar';
import ConfirmDialog from '../../components/dialogs/Confirm.dialog';
import AppPopOverMenu from '../../components/AppPopOverMenu/index';

// ----------------------------------------------------------------------



const TABLE_HEAD = [
  { id: 'SNo', label: 'SNo.', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'plot', label: 'Plot', alignRight: false },
  { id: 'agentName', label: 'Agent', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListingPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState('');
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);



  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getPropertiesAs()
    return () => {
      setData([])
    };
  }, []);

  // get users from api
  const getPropertiesAs = () => {
    setIsLoading(true);
    getProperties()
      .then((data) => setData(data))
      .catch(() => showSnackbar().failure('Something went wrong'))
      .finally(() => setIsLoading(false));

  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);


  /**
   * Property delete
   */
  const deletePropertyItem = () => {
    if (selectedListItem) {
      deleteProperty(selectedListItem)
        .then(() => showSnackbar().success('Listing deleted successfully'))
        .catch(() => showSnackbar().failure("Something went wrong!."))
        .finally(() => {
          setOpenDeleteDialog(false);
          setSelectedListItem('');
          getPropertiesAs();
        })
    }

  }

  /**
   * Property status update
   * @param {string} propertyId 
   * @param {string} status 
   */
  const pauseProperty = (propertyId, status) => {
    status = status === "active" ? "inactive" : "active";
    const statusMsg = status === "active" ? "resumed" : "paused";
    if (propertyId) {
      updateProperty(propertyId, {status})
        .then(() => showSnackbar().success(`The selected listing has been ${statusMsg} successfully.`))
        .catch(() => showSnackbar().failure("Something went wrong!."))
        .finally(() => {          
          getPropertiesAs();
        })
    }

  }


  return (
    <>
      <Helmet>
        <title> Admin</title>
      </Helmet>
      <NavHeader />
      <div className='banner-wrp' style={{ marginTop: '40px' }}>
        <img src={innerBanner} alt='banner' />
      </div>
      <Container style={{ paddingTop: '30px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>All Listings</Typography>

          <Link to={'/dashboard/add-property'} >
            <Button variant="contained" className='blu-btn' startIcon={<Iconify icon="eva:plus-fill" />}>
              New listing
            </Button>
          </Link>
        </Stack>

        <Card className='table-request'>
          <UserListToolbar className='list-table-search' numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table className='listing-table'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, k) => {
                    const { id, title, price, location, plot, agentName, status, images } = row;
                    const selectedUser = selected.indexOf(title) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" className='filter-head' selected={selectedUser}>
                        <TableCell padding="checkbox">{k + 1}
                          {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, title)} /> */}
                        </TableCell>

                        <TableCell align="center" component="th" scope="row" padding="none">
                          <Stack direction="row" align="center" justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Avatar className='propertyImg' alt={title} src={(images.length > 0 && images[0]) ? images[0].tempURL : ''} />
                            <Typography variant="subtitle2" align="center" style={{ fontWeight: 'normal' }} noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="center">{price}</TableCell>

                        <TableCell align="center">{location}</TableCell>

                        <TableCell align="center">{plot}</TableCell>

                        <TableCell align="center">{agentName}</TableCell>

                        <TableCell align="center">
                          {(status === 'active' && <span className='tabstatus'><img src={complete} alt="complete" /></span>) ||
                            (status === 'inactive' && <span className='tabstatus'><img src={incomplete} alt="complete" /></span>)}
                        </TableCell>

                        <TableCell align="right">
                          <AppPopOverMenu options={[
                            {
                              label: 'View',
                              onClick: () => {
                                navigate(`/dashboard/property/view/${id}`)
                              }
                            },
                            {
                              label: 'Edit',
                              onClick: () => {
                                navigate(`/dashboard/edit/${id}`)
                              }
                            },
                            {
                              label: status === "active" ? "Pause" : "Resume",
                              onClick: () => {
                                pauseProperty(id, status, images)
                              }
                            },
                            {
                              label: "Delete",
                              onClick: () => {
                                setSelectedListItem(id);
                                setOpenDeleteDialog(true);
                              }
                            }
                          ]} />



                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>

                {filteredUsers.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {isLoading ? "Loading..." : "No results found"}
                          </Typography>

                          {(filterName) && <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {openDeleteDialog && <ConfirmDialog
            isOpen={openDeleteDialog}
            onClose={() => {
              setOpenDeleteDialog(false);
              setSelectedListItem('');
            }}
            title={`Listing deletion`}
            className={'delete-popup'}
            description={`Are you sure you want to delete this listing?\nThis action is permanent and cannot be undone.`}
            okayButtonText={'Delete listing'}
            onConfirm={deletePropertyItem}
          />}

        </Card>
      </Container>


    </>
  );
}
